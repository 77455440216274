<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>教务数据分析</a-breadcrumb-item>
            <a-breadcrumb-item>课消分析</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                </div>
                <div class="search">
                    <a-form layout='inline'>
                    <a-form-item>
                        <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
                        <a-radio-button value="day">
                            日
                        </a-radio-button>
                        <a-radio-button value="week">
                            周
                        </a-radio-button>
                        <a-radio-button value="month">
                            月
                        </a-radio-button>
                        <a-radio-button value="year">
                            年
                        </a-radio-button>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>时间筛选</span>
                        </template>
                        <a-range-picker
                            :ranges="rangesData"
                            :value='rangesValue'
                            :disabled-date="disabledDate" :allowClear="false"
                            :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                            @change="(date, dateString)=>onChange(date, dateString,1)" />
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>上课校区</span>
                        </template>
                        <a-select allowClear showSearch placeholder="请选择校区" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' :filterOption="filterOption" @change="handleChange" style="width: 220px;">
                            <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-button @click="toScreen" type="primary">筛选</a-button>
                    </a-form-item>
                    </a-form>
                </div>
            </div>
            <div class="dashboard bk-group">
                <statistics :count='counts'/>
                <a-row class="mb16" :gutter="16">
                    <a-col :lg="24" :xl="24">
                        <amount :loading='loadingA' :item ='courseA' :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <courseBalance :loading='loading' :item ='course' :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <courseBalanceCosts :loading='loadingC' :item ='courseC' :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <LRanking title="校区签约课时排行" :rank_data="courseS" :loading="loadingS" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />    
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <!-- <courseBalanceStudioCosts title="校区课时消耗排行" :loading='loadingSC' :item ='courseSC' :start_date='paramsData.start_date' :end_date='paramsData.end_date'/> -->
                        <LRanking title="校区课时消耗排行" line_color='#ec4758' :rank_data="courseSC" :loading="loadingSC" :start_date='paramsData.start_date' :end_date='paramsData.end_date' /> 
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <LRanking title="校区签约金额排行" :rank_data="courseAmount" :loading="loadingS" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />    
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <!-- <courseBalanceStudioCosts title="校区课消金额排行" :loading='loadingSC' :item ='courseSCAmount' :start_date='paramsData.start_date' :end_date='paramsData.end_date'/> -->
                        <LRanking title="校区课消金额排行" line_color='#ec4758' :rank_data="courseSCAmount" :loading="loadingSC" :start_date='paramsData.start_date' :end_date='paramsData.end_date' /> 
                    </a-col>

                </a-row>
            </div>
        </div>
    </div>
</template>

<script>
import ranges from "@/common/mixins/ranges"
import moment from 'moment'
import statistics from '@/views/bi/ranking/money/statistics'
import courseBalance from './courseBalance'
import courseBalanceCosts from './courseBalanceCosts'
import amount from './amount'
    export default {
        name:'kxsjtj',
        data() {
            return {
                loading:false,
                loadingA:false,
                loadingC:false,
                loadingS:false,
                loadingSC:false,
                course:{},
                courseA:{},
                courseC:{},
                courseS:{},
                courseAmount:{},
                courseSC:{},
                courseSCAmount:{},
                counts:[],
                studios:[],
                paramsData:{
                    start_date:moment().startOf('month').format('YYYY-MM-DD'),
                    end_date:moment().format('YYYY-MM-DD'),
                    studio_id:'',
                    filter_type:'day'
                },
            }
        },
        mixins: [ranges],
        components:{
            amount,
            statistics,
            courseBalance,
            courseBalanceCosts,
        },
        created() {
            this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
            this.toScreen()
            this.getStudio()
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            getList(obj) {
                this.getCount(obj)
                this.getAmount(obj)
                this.getCourseBalance(obj)
                this.getCourseBalanceCosts(obj)
                this.getCourseBalanceStudio(obj)
                this.getCourseBalanceStudioCosts(obj)
            },

            async getCourseBalance(obj){
                this.loading = true
                let res = await this.$store.dispatch('analysisCourseBalanceAction', obj)
                this.course = res.data
                this.loading = false
            },
            async getAmount(obj){
                this.loadingA = true
                let res = await this.$store.dispatch('analysisCourseBalanceAmountAction', obj)
                this.courseA = res.data
                this.loadingA = false
            },
            async getCourseBalanceCosts(obj){
                this.loadingC = true
                let res = await this.$store.dispatch('analysisCourseBalanceCostsAction', obj)
                this.courseC = res.data
                this.loadingC = false
            },
            async getCourseBalanceStudio(obj){
                this.loadingS = true
                let res = await this.$store.dispatch('analysisCourseBalanceStudioAction', obj)
                this.courseS = res.data.times

                this.courseAmount = res.data.amount
                this.loadingS = false
            },
            async getCourseBalanceStudioCosts(obj){
                this.loadingSC = true
                let res = await this.$store.dispatch('analysisCourseBalanceStudioCostsAction', obj)
                this.courseSC = res.data.times;

                this.courseSCAmount = res.data.amount;
                this.loadingSC = false
            },
            async getStudio() {
                let res = await this.$store.dispatch('searchBelongStudioAction', {})
                this.studios = res.data
            },
            async getCount(obj){
                let res = await this.$store.dispatch('analysisCourseBalanceCountAction', obj)
                this.counts = res.data
            },
            handleChange(value){
                this.paramsData.studio_id = value;
            },
            handleButChange(e){
                this.paramsData.filter_type = e.target.value
                this.toScreen();
            },
            toScreen(){
                let obj = {
                    search:this.paramsData
                }
                this.getList(obj);
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>