<template>
  <a-card title="">
    <div>
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <ve-histogram 
        :data="item" 
        :title="{
            text:`课消金额统计 ${item.title?`(${item.title})`:''}`,
            subtext: start_date==end_date?`${start_date}`:`${start_date} ~ ${end_date}`,
            textStyle: {
                fontSize: 15,
            },
            subtextStyle: {
                fontSize: 12.5
            }
        }"
        :legend="{show:true,bottom:10}"
        judge-width
        :grid="{right: '5%'}"
        :mark-line="markLine"
        :toolbox="toolbox"
        :settings="{label: { show: true, position: 'top'},yAxisType: ['KMB', 'KMB'], axisSite: { right: ['到课学员','缺勤学员'] },showLine: ['到课学员','缺勤学员']}" 
        :extend="histogramChartExtend" >
        </ve-histogram>
        <LEmpty v-if="first>0 && item.rows.length == 0"/>
    </div>
  </a-card>
</template>


<script>
export default {
  name: 'room',
  props:['item' , 'start_date', 'end_date','loading'],

  data () {
    this.toolbox = {
        feature: {
        magicType: {},
        saveAsImage: {pixelRatio:2}
        }
    },
    this.colors = ['#ec4758']
    this.markLine = {
      symbol: ['none', 'none'], //去掉箭头
      data: [
        {
          name: '平均线',
          type: 'average'
        }
      ]
    }
    return {
      first:0,
      histogramChartExtend:{
          series(item){
              if(item){
                  item[0].barMaxWidth = 10
                  item[0].itemStyle = Object.assign({}, {
                      normal:{
                          color: function(params) {
                              return {
                                  type: 'linear',
                                  x:1,
                                  y:1,
                                  x2:0,
                                  y2:0,
                                  colorStops: [{
                                      offset: 0,
                                      color: '#ffffff'
                                      
                                  }, {
                                      offset: 1,                    
                                      color: '#23d9ae'
                                  }],
                              }
                          },
                          barBorderRadius:[30,30,0,0]
                      }
                  })
                  item[1].itemStyle = Object.assign({}, {
                      color: '#3275FB',
                  })
                  item[1].symbolSize="8"
                  item[1].symbol="circle"
                  item[1].smooth=false
                  item[1].label = Object.assign({}, {
                      show:false,
                      position: "top"
                  })


                  item[2].itemStyle = Object.assign({}, {
                      color: '#f08194',
                  })
                  item[2].symbolSize="8"
                  item[2].symbol="circle"
                  item[2].smooth=false
                  item[2].label = Object.assign({}, {
                      show:false,
                      position: "top"
                  })
              }
              return item
          },
          xAxis:{
              axisLine:{
                  show:true,
                  lineStyle: {
                      type: 'dotted',
                  }
              },
          },
          yAxis(item){
              item[0].axisLine = Object.assign({}, {
                  show:false
              })
              item[1].axisLine = Object.assign({}, {
                  show:false
              })
              item[0].splitLine = Object.assign({}, {
                  show:true,
                  lineStyle: {
                      type: 'dotted'
                  }
              })
              item[1].splitLine = Object.assign({}, {
                  show:false,
                  lineStyle: {
                      type: 'dotted'
                  }
              })
              return item
          },
      }
    }
  },
  watch: {
        loading(newValue, oldValue) {
            this.first++;
        }
    },
}
</script>
